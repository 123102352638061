/* eslint-disable @typescript-eslint/no-empty-function */
import { getMetrikaPlatform } from '@metrika/ui-kit';
import { version } from '../../package.json';
import type { GetAuditLogRequest } from '../alerts-v3/api/auditLog.types';
import type { OrgUserGroupGetRequest } from '../user/types';

const isProdLocalhost =
   window.location.hostname === 'localhost' &&
   (window.location.port === '3003' ||
      window.location.port === '3002' ||
      window.location.port === '3012' ||
      window.location.port === '3022');

const isProd = process.env.REACT_APP_ENV === 'PROD' || isProdLocalhost;
//const isLocalhost = window.location.hostname === 'localhost';
const isStudio = () => getMetrikaPlatform() === 'studio';

const isLocalhost = window.location.hostname === 'localhost';

export type AppType = 'login' | 'risk' | 'web' | 'unknown';
function getAppType(): AppType {
   if (
      window.location.hostname.startsWith('www') ||
      window.location.hostname.startsWith('metrika.co') ||
      window.location.port === '3003'
   ) {
      return 'web';
   }
   if (
      window.location.hostname.startsWith('login.') ||
      window.location.host === 'localhost:3012' || // production localhost
      window.location.host === 'localhost:3011' //staging localhost
   ) {
      return 'login';
   }
   if (
      window.location.hostname.startsWith('risk.') ||
      window.location.host === 'localhost:3022' || // production localhost
      window.location.host === 'localhost:3021' //staging localhost
   ) {
      return 'risk';
   }
   return 'unknown';
}

function getLoginReturnToDomain() {
   const LocalPortToDoman = {
      '3001': 'https://app.staging.aws.metrika.co',
      '3002': 'https://app.metrika.co',
      '3011': 'https://login.staging.aws.metrika.co',
      '3012': 'https://login.metrika.co',
      '3021': 'https://risk.staging.aws.metrika.co',
      '3022': 'https://risk.metrika.co',
   };

   if (window.location.hostname !== 'localhost') {
      return window.location.origin;
   }
   // @ts-ignore
   return LocalPortToDoman[window.location.port] || window.location.origin;
}

const getAppDomains = () => {
   return {
      login: isLocalhost
         ? isProd
            ? 'http://localhost:3012'
            : 'http://localhost:3011'
         : isProd
           ? 'https://login.metrika.co'
           : 'https://login.staging.aws.metrika.co',
      risk: isLocalhost
         ? isProd
            ? 'http://localhost:3022'
            : 'http://localhost:3021'
         : isProd
           ? 'https://risk.metrika.co'
           : 'https://risk.staging.aws.metrika.co',
      loginReturnTo: getLoginReturnToDomain(),
      web: 'https://www.metrika.co',
   };
};

export const appEnv = {
   appType: getAppType,
   appDomains: getAppDomains(),
   isAppRisk: getAppType() === 'risk',
   isAppLogin: getAppType() === 'login',
   isAppWeb: getAppType() === 'web',
   isProd,
   version,
   env: process.env.REACT_APP_ENV,
   buildDate: process.env.REACT_APP_BUILD_DATE,
   sentryEnv: process.env.REACT_APP_SENTRY_ENV,
   sentryVersion: process.env.REACT_APP_SENTRY_VERSION,
   sentryEnabled:
      (process.env.NODE_ENV === 'production' || process.env.REACT_APP_SENTRY_ENV === 'DEV-TEST') &&
      process.env.REACT_APP_SENTRY_ENABLED === 'true',
   unleashClientKey: process.env.REACT_APP_UNLEASH_CLIENT_KEY,
   flags: {
      //current hidden features
      chartingServiceGridEditor: process.env.REACT_APP_FLAG_CHARTING_SERVICE_GRID_EDITOR === 'true',
      //debug
      debugEnabled:
         process.env.REACT_APP_DEBUG_ENABLED === 'true' ||
         window.location.hostname === 'localhost' ||
         process.env.NODE_ENV === 'development',
      debugShow: false, // on press "D" - show/hide all
      debugPersist: false, // save/reload to/from localstorage on page refresh
      debugShowTilePanel: false, // show/hide tile debug panel
      debugShowGridPanel: false, // show/hide Grid debug panel
      useCsDebugApi: false, // profile charting service
      mockedNodes: false,
      mockedNodesEmpty: false,
      apiDelay: false,
      forceStandard: false,
      forceSingleOrg: false,
      forceUserNotDeletable: false,
      debugLayout: false,
      debugDevTools: false,
      debugDevToolsRedux: false,
      devMockedDashboards: false,
      devFeViews: false,
      useCsProdData: false,
      useDraftData: false,
      useDots: false,
      useCsAesQuery: false,
   },
   api: {
      riskKriCatalog: '/api/v1/risk/kricatalog/kris',
      riskAssessmentsVersions: (assessmentId: string) => `/api/v1/risk/assessments/${assessmentId}/versions`,
      riskScoringGetWeights: (assessmentId: string) => `/api/v1/risk/assessments/${assessmentId}/weights`,
      riskScoringPostWeights: (assessmentId: string) => `/api/v1/risk/assessments/${assessmentId}/weights`,
      riskPostAssessmentScoring: () => `/api/v1/risk/score`,
      riskGetAssessmentScoringDetails: (scoreId: string) => `/api/v1/risk/score/${scoreId}`,
      riskGetAssessmentScoring: (assessmentId: string) => `/api/v1/risk/score/${assessmentId}`,
      riskGetAssessmentReviewByIdRisk: (orgId: string, assessmentId: string, reviewId: string, riskId: string) =>
         `/api/v1/risk/assessments/g/${orgId}/assessment/${assessmentId}/asset/reviews/${reviewId}/risk/${riskId}`,
      riskGetAssessmentReviewByIdRiskComments: (
         orgId: string,
         assessmentId: string,
         reviewId: string,
         riskId: string,
      ) =>
         `/api/v1/risk/assessments/g/${orgId}/assessment/${assessmentId}/asset/reviews/${reviewId}/risk/${riskId}/comments`,
      riskGetAssessmentOverview: (orgId: string) => `/api/v1/risk/assessments/g/${orgId}/assessment/incidents-overview`,
      riskGetAssessmentReviews: (orgId: string, assessmentId: string) =>
         `/api/v1/risk/assessments/g/${orgId}/assessment/${assessmentId}/asset/reviews`,
      riskGetAssessmentReviewById: (orgId: string, assessmentId: string, reviewId: string) =>
         `/api/v1/risk/assessments/g/${orgId}/assessment/${assessmentId}/asset/reviews/${reviewId}`,
      riskGetAssetsList: `/api/v1/risk/assets`,
      riskPostAssessment: (orgId: string) => `/api/v1/risk/assessments/g/${orgId}`,
      riskGetAssessment: (orgId: string, assessmentId: string) =>
         `/api/v1/risk/assessments/g/${orgId}/assessment/${assessmentId}`,
      riskGetAssessmentIncidents: (orgId: string, assessmentId: string) =>
         `/api/v1/risk/assessments/g/${orgId}/assessment/${assessmentId}/incidents`,
      riskGetAssessmentIncidentsStats: (orgId: string, assessmentId: string) =>
         `/api/v1/risk/assessments/g/${orgId}/assessment/${assessmentId}/incidents/stats`,
      riskGetAssessmentRiskIncidents: (orgId: string, assessmentId: string, riskId: string) =>
         `/api/v1/risk/assessments/g/${orgId}/assessment/${assessmentId}/risk/${riskId}/incidents`,
      riskPutAssessment: (orgId: string, assessmentId: string) =>
         `/api/v1/risk/assessments/g/${orgId}/assessment/${assessmentId}`,
      riskPutAssessmentStatus: (orgId: string, assessmentId: string) =>
         `/api/v1/risk/assessments/g/${orgId}/assessment/${assessmentId}/status`,
      riskPutAseessmentRiskKrirs: (orgId: string, assessmentId: string, riskId: string) =>
         `/api/v1/risk/assessments/g/${orgId}/assessment/${assessmentId}/risk/${riskId}/kris`,
      riskGetAssessmentsAssetsSidebar: (orgId: string) => `/api/v1/risk/assessments/g/${orgId}/assets`,
      riskGetFrameworksCategories: (orgId: string) => `/api/v1/risk/frameworks/g/${orgId}/categories`,
      riskGetFrameworksApproved: (orgId: string, categoryId: string, assetType: string) =>
         `/api/v1/risk/frameworks/g/${orgId}/approved?category_id=${categoryId}&asset_type=${assetType}`,
      riskGetFrameworksSummary: (orgId: string) => `/api/v1/risk/frameworks/g/${orgId}/summary`,
      riskGetFrameworkDetails: (orgId: string, frameworkId: string) =>
         `/api/v1/risk/frameworks/g/${orgId}/framework/${frameworkId}`,
      userGroupEditDelete: (id: string) => `/api/v2/user/settings/userGroups/${id}`,
      userGroupPost: '/api/v2/user/settings/userGroups',
      userGroupsGet: (params: OrgUserGroupGetRequest) =>
         `/api/v2/user/settings/userGroups?page=${params.page || '1'}${
            params.pageSize ? '&pageSize=' + params.pageSize : ''
         }${params.cursor ? '&cursor=' + params.cursor : ''}${params.sort ? '&sortKey=' + params.sort : ''}${
            params.sortOrder ? '&sortOrder=' + params.sortOrder : ''
         }`,
      auditLog: (params: GetAuditLogRequest) =>
         `/api/v2/user/logs/g/${params.orgId}?page=${params.page || '1'}${
            params.pageSize ? '&pageSize=' + params.pageSize : ''
         }${params.cursor ? '&cursor=' + params.cursor : ''}${params.sort ? '&sortKey=' + params.sort : ''}${
            params.sortOrder ? '&sortOrder=' + params.sortOrder : ''
         }${params.user ? '&user=' + params.user : ''}${params.protocol ? '&protocol=' + params.protocol : ''}`,
      /* login */
      loginBrowser: '/api/v1/auth/self-service/login/browser',
      logoutBrowser: '/api/v1/auth/self-service/logout/browser',

      /* user v2 */
      meV2: `/api/v2/user/me`,
      meSettingsV2: `/api/v2/user/me/settings`,
      meSettingsNotificationsV2: `/api/v2/user/me/settings/notifications`,

      /* charting service */
      chartIdV2: (id: string, prodData?: boolean, draftData?: boolean) =>
         `/api/${prodData ? 'v3' : 'v2'}/${isStudio() ? 'chart' : 'chart-go'}/chart/${draftData ? 'draft/' : ''}${
            appEnv.flags.useCsDebugApi ? 'profile/' + id : id
         }`,

      /* dashboard service */
      dashboardsV2: `/api/v2/dash/dashboards`,
      dashboardDetailsV2: (fullPath: string, draft: boolean) => `/api/v2/dash${fullPath}${draft ? '?draft=true' : ''}`,
   },
};

if (window) {
   // @ts-ignore
   window.$$AlertHub = {
      env: appEnv.env,
      version: appEnv.version,
      buildDate: appEnv.buildDate,
      sentryVersion: appEnv.sentryVersion,
      sentryEnv: appEnv.sentryEnv,
      sentryEnabled: appEnv.sentryEnabled,
   };
   if (!appEnv.flags.debugEnabled) {
      // @ts-ignore
      window.console = {
         debug: () => {},
         log: () => {},
         info: () => {},
         warn: () => {},
         error: () => {},
         assert: () => {},
         clear: () => {},
         count: () => {},
         countReset: () => {},
         dir: () => {},
         dirxml: () => {},
         group: () => {},
         groupCollapsed: () => {},
         groupEnd: () => {},
         table: () => {},
         time: () => {},
         timeEnd: () => {},
         timeLog: () => {},
         timeStamp: () => {},
         trace: () => {},
      };
   }
}
